import { wallet } from "@fscrypto/domain";
import { createStore, useStore } from "@fscrypto/state-management";
import { useNetworks } from "./use-networks";

type AddWalletModal = {
  isModalOpen: boolean;
  isLoadingNetworks?: boolean;
  hideSelectNetwork?: boolean;
  hideUnverifiedAddressInput?: boolean;
  selectedNetwork?: wallet.Network;
  newAddress?: wallet.AddressUpsert;
};

const addWalletModalStore = createStore<AddWalletModal>({ isModalOpen: false });

export const useAddWalletModal = () => {
  const store = useStore(addWalletModalStore);
  const { networks, fetch: fetchNetworks } = useNetworks();

  return {
    ...store,
    initialize: () => fetchNetworks(),
    networks,
    setModalOpen: (preselectedNetwork?: string, hideUnverifiedAddressInput?: boolean) => {
      const network = networks?.find((n) => n.chain === preselectedNetwork);
      addWalletModalStore.set({
        ...store,
        isModalOpen: true,
        selectedNetwork: network,
        hideUnverifiedAddressInput,
        hideSelectNetwork: !!network,
      });
    },
    setModalClose: () => addWalletModalStore.set({ ...store, isModalOpen: false }),
    setSelectedNetwork: (network: wallet.Network) => addWalletModalStore.set({ ...store, selectedNetwork: network }),
    setNewAddress: (address: wallet.AddressUpsert) => addWalletModalStore.set({ ...store, newAddress: address }),
  };
};
