import { wallet } from "@fscrypto/domain";
import { Client } from "@fscrypto/http";
import { createAsyncStore, useObservableValue, useStore } from "@fscrypto/state-management";
import { $path } from "remix-routes";
import { z } from "zod";

const client = new Client();

const getNetworks = async (profileId?: string) => {
  return client.get(
    $path(`/api/networks`, profileId ? new URLSearchParams({ profileId }) : undefined),
    z.array(wallet.networkSchema),
  );
};

const asyncStore = createAsyncStore<wallet.Network[]>(getNetworks);

export const useNetworks = () => {
  const networks = useStore(asyncStore);
  const isLoading = useObservableValue(asyncStore.loading$);

  return {
    networks,
    isLoading,
    fetch: asyncStore.fetchData,
  };
};
